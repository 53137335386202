import {FieldValue} from 'firebase/firestore';

interface PlayerData {
  name: string;
  cardValue?: number;
}
interface PlayerDataUpdate {
  name?: string;
  cardValue?: number | FieldValue;
}

interface GameData {
  status: GameStatus;
  owner: string;
  cards: number[];
  participants: {[key: string]: PlayerData};
}

interface GameDataUpdate {
  status?: GameStatus;
  owner?: string;
  cards?: number[];
  participants?: {[key: string]: PlayerDataUpdate | FieldValue};
}

enum GameStatus {
  Idle,
  Active,
}

export {GameStatus};
export type {PlayerData, GameData, PlayerDataUpdate, GameDataUpdate};
