function Datenschutz() {
  return (
    <div className="Datenschutz">
      <header className="app-header">
        <h1>Datenschutzerklärung</h1>
      </header>
      <h2>Verantwortlich im Sinne der Datenschutzgesetzes:</h2>
      <p>
        Kolja Markwardt
        <br />
        Jarrestr. 64
        <br />
        22303 Hamburg
        <br />
        info @ koljatm.de
        <br />
      </p>
      <h3>Datenschutz</h3>
      <p>
        Als Webseitenbetreiber nehmen wir den Schutz aller persönlichen Daten
        sehr ernst. Alle personenbezogenen Informationen werden vertraulich und
        gemäß den gesetzlichen Vorschriften behandelt, wie in dieser
        Datenschutzerklärung erläutert. Unserer Webseite kann selbstverständlich
        genutzt werden, ohne dass Sie persönliche Daten angeben. Wenn jedoch zu
        irgendeinem Zeitpunkt persönliche Daten wie z.B. Name, Adresse oder
        E-Mail abgefragt werden, wird dies auf freiwilliger Basis geschehen.
        Niemals werden von uns erhobene Daten ohne Ihre spezielle Genehmigung an
        Dritte weitergegeben. Datenübertragung im Internet, wie zum Beispiel
        über E-Mail, kann immer Sicherheitslücken aufweisen. Der komplette
        Schutz der Daten ist im Internet nicht möglich.
      </p>
      <h3>Google Firebase Dienste [erforderlich]</h3>
      <p>
        Diese Seite nutzt die Dienste Firestore und Authentication von Google
        Firebase (Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
        94043, USA) zur Bereitstellung der Funktionalität. Einige Firebase
        Dienste verarbeiten personen- oder gerätebezogene Daten. Über Dienste
        von Google Firebase ist nicht auszuschließen, dass Daten auch in andere
        Länder (z.B. den USA) übertragen werden. Weitere Informationen zum
        Datenschutz im Zusammenhang mit Google Firebase finden Sie unter:{' '}
        <a href={'https://www.firebase.com/terms/privacy-policy.html'}>
          https://www.firebase.com/terms/privacy-policy.html
        </a>
        .
      </p>
      <h3>Auskunft, Löschung, Sperrung</h3>
      <p>
        Zu jedem Zeitpunkt können Sie sich über die personenbezogenen Daten,
        deren Herkunft und Empfänger und den Nutzen der Datenverarbeitung
        informieren und unentgeltlich eine Korrektur, Sperrung oder Löschung
        dieser Daten verlangen. Bitte nutzen Sie dafür die im Impressum
        angegebenen Kontaktwege. Für weitere Fragen zum Thema stehen wir Ihnen
        ebenfalls jederzeit zur Verfügung.
      </p>
      <h3>Server-Log-Files</h3>
      <p>
        Der Seiten-Provider erhebt und speichert automatisch Daten in Server-Log
        Files, die von Ihrem Browser an uns übermittelt werden. Diese Daten
        enthalten:
        <ul>
          <li>Browsertyp/ Browserversion</li>

          <li>Betriebssystem des Rechners</li>

          <li>Referrer URL</li>

          <li>Hostname des zugreifenden Rechners</li>

          <li>Uhrzeit der Serveranfrage</li>
        </ul>
        Diese Daten sind nicht personenbezogen. Es erfolgt keine Zusammenführung
        dieser Daten mit anderen Datenquellen. Wenn uns konkrete Anhaltspunkte
        für eine rechtswidrige Nutzung bekannt werden behalten wir uns das Recht
        vor, diese Daten nachträglich zu überprüfen.
      </p>

      <h3>Cookies</h3>
      <p>
        Viele Internetseiten verwenden Cookies. Cookies sind unschädlich für
        Ihren Rechner und virenfrei. Sie dienen dazu, Internet-Angebote für die
        Besucher einer Webseite freundlicher, effektiver und sicherer zu machen.
        Cookies sind kleine Textdateien, die auf Ihrem Computer abgelegt werden
        und die Ihr Browser verwendet. Cookies können auf Ihrem Computer
        gespeichert bleiben, bis Sie diese löschen. Diese Cookies helfen dabei,
        Ihren Rechner beim nächsten Besuch wiederzuerkennen. Über die
        Browsereinstellungen können sie festlegen, dass Sie über neue Cookies
        informiert werden und Cookies jeweils annehmen müssen. Ebenso können Sie
        die Annahme von Cookies für bestimmte Fälle oder generell ausschließen
        oder das automatische Löschen der Cookies beim Schließen des Browser
        aktivieren. Werden Cookies desaktiviert, kann die Funktionalität unserer
        Website eingeschränkt sein.
      </p>
      <h3>Änderung der Datenschutzbestimmungen</h3>
      <p>
        Unsere Datenschutzerklärung kann in unregelmäßigen Abständen angepasst
        werden, damit sie den aktuellen rechtlichen Anforderungen entsprechen
        oder um Änderungen unserer Dienstleistungen umzusetzen, z. B. bei der
        Einfügung neuer Angebote. Für Ihren nächsten Besuch gilt dann
        automatisch die neue Datenschutzerklärung. Kontakt zum
        Datenschutzmitarbeiter Für Fragen zum Datenschutz schicken Sie uns bitte
        eine Nachricht an info @ koljatm.de mit dem Betreff „Datenschutz“.
      </p>
      <p>
        Diese Widerrufsbelehrung Seite wurde bei datenschutzerklärungmuster.de
        erstellt.
      </p>
    </div>
  );
}

export default Datenschutz;
