import {FirebaseApp, initializeApp} from 'firebase/app';
import {useContext} from 'react';
import {ConsentContext, ConsentStatus} from '../Consent';

if (!process.env.REACT_APP_APIKEY) {
  console.error('firebase config not defined in .env');
}

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
};

let app = null;

const useFirebase: () => [FirebaseApp | null] = () => {
  const consent = useContext(ConsentContext);

  if (consent !== ConsentStatus.Unknown) {
    app = initializeApp(firebaseConfig);
  } else {
    app = null;
  }
  return [app];
};

export default useFirebase;
