function Privacy() {
  return (
    <div className="Datenschutz">
      <header className="app-header">
        <h1>Privacy policy</h1>
      </header>
      <h2>Responsible person:</h2>
      <p>
        Kolja Markwardt
        <br />
        Jarrestr. 64
        <br />
        22303 Hamburg
        <br />
        info @ koljatm.de
        <br />
      </p>
      <h3>Privacy policy</h3>
      <p>
        As a website operator, we take the protection of all personal data very
        seriously. All personal information will be kept confidential and
        treated according to the legal regulations, as explained in this Privacy
        policy. Our website can of course be used without providing any personal
        data. However, if Personal data such as name, address or e-mail is
        provided, this is done on a voluntary basis. We will never transmit any
        data we collect to third parties without your specific permission. Data
        transmission on the Internet, such as via e-mail, can always have
        security gaps. It is not possible to protect data completely on the
        Internet.
      </p>
      <h3>Google Firebase Services [necessary]</h3>
      <p>
        This site uses the Firestore and Authentication services from Google
        Firebase (Google Inc., 1600 Amphitheater Parkway, Mountain View, CA
        94043, USA) to provide its functionality. Some Firebase Services process
        personal or device-related data. By using Google Firebase services the
        possibility of data being transferred to other Countries (e.g. the USA)
        cannot be ruled out. You can find more information about data protection
        in connection with Google Firebase at:{' '}
        <a href={'https://www.firebase.com/terms/privacy-policy.html'}>
          https://www.firebase.com/terms/privacy-policy.html
        </a>
        .
      </p>
      <h3>Information, deletion, blocking</h3>
      <p>
        At any time you can find out about the personal data, their origin and
        recipient and the benefits of data processing and request a correction,
        blocking or deletion free of charge. Please use the information and
        contact channels specified in the imprint. We are at your disposal for
        further questions on the subject at any time.
      </p>
      <h3>Server-Log-Files</h3>
      <p>
        The website provider automatically collects and saves data in the server
        log Files that are transmitted to us by your browser. These dates
        contain:
        <ul>
          <li>Browser type/ Browser version</li>

          <li>Operating system</li>

          <li>Referrer URL</li>

          <li>Hostname of the accessing computer</li>

          <li>Time of access</li>
        </ul>
        These data are not personal. There is no merging this data with other
        data sources. If we have concrete clues about illegal use we reserve the
        right to check this data retrospectively.
      </p>

      <h3>Cookies</h3>
      <p>
        Many websites use cookies. Cookies are harmless to Your computer and
        virus-free. They are used to make websites visitor-friendlier, more
        effective and safer. Cookies are small text files that are placed on
        your computer and which your browser is using. Cookies can be on your
        computer and remain saved until you delete them. These cookies help
        recognize your computer the next time you visit. The browser settings
        allow you to specify that want too be informed about new cookies and
        must accept cookies in each case. Likewise, you can exclude the
        acceptance of cookies for certain cases or in general or the automatic
        deletion of cookies when you close the browser. If cookies are
        deactivated, the functionality of our Website may be restricted.
      </p>
      <h3>Changes to the privacy policy</h3>
      <p>
        Our data protection declaration can be adjusted at irregular intervals
        so that they comply with current legal requirements or to implement
        changes to our services, e.g. at the addition of new services. The new
        data protection declaration apply to your next visit automatically.
        Contact to Data protection officer If you have any questions about data
        protection, please send us a message to info @ koljatm.de with the
        subject "data protection".
      </p>
      <p>This privacy policy was created at datenschutzerklärungmuster.de</p>
    </div>
  );
}

export default Privacy;
