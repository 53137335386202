enum CardStatus {
  Unknown = 'unknown',
  Hidden = 'hidden',
  Revealed = 'revealed',
}

function Card({
  value,
  status = CardStatus.Unknown,
  highlighted = false,
  onClick = () => {},
}) {
  return (
    <div
      className={
        'starry-night card ' + status + (highlighted ? ' highlighted' : '')
      }
      onClick={onClick}
    >
      <div className={'card-value'}>
        {status === CardStatus.Revealed ? value : ''}
      </div>
    </div>
  );
}

export {CardStatus};
export default Card;
