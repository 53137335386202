import {createContext, useState, FC, useEffect} from 'react';
import Modal from 'react-modal';
import Cookies from 'js-cookie';
import MemoizedFormattedMessage from 'react-intl/lib/src/components/message';
import * as React from 'react';
import {useIntl} from 'react-intl';

enum ConsentStatus {
  Unknown = 'unknown',
  All = 'all',
  Limited = 'limited',
}

const cookieName = 'consent-0';

const ConsentContext = createContext(ConsentStatus.Unknown);

const Consent: FC = ({children}) => {
  const [status, setStatus] = useState(ConsentStatus.Unknown);
  useEffect(() => {
    const consentCookie = Cookies.get(cookieName);
    if (consentCookie === ConsentStatus.All) {
      setStatus(ConsentStatus.All);
    } else if (consentCookie === ConsentStatus.Limited) {
      setStatus(ConsentStatus.Limited);
    }
  }, []);

  const intl = useIntl();

  function selectStatus(status: ConsentStatus) {
    setStatus(status);
    if (status === ConsentStatus.All) {
      Cookies.set(cookieName, ConsentStatus.All, {expires: 30});
    } else if (status === ConsentStatus.Limited) {
      Cookies.set(cookieName, ConsentStatus.Limited, {expires: 30});
    } else {
      Cookies.remove(cookieName);
    }
  }

  return (
    <ConsentContext.Provider value={status}>
      <Modal isOpen={status === ConsentStatus.Unknown} className="Modal">
        <h2>
          <MemoizedFormattedMessage id={'consentTitle'} />
        </h2>
        <p>
          <MemoizedFormattedMessage id={'consentText'} />
          <a href={intl.formatMessage({id: 'privacyLink'})} target={'_blank'}>
            <MemoizedFormattedMessage id={'privacyPolicy'} />
          </a>
        </p>
        <button
          className={'consent-all'}
          onClick={() => selectStatus(ConsentStatus.All)}
        >
          <MemoizedFormattedMessage id={'consentAcceptAll'} />
        </button>
        <button
          className={'consent-limited'}
          onClick={() => selectStatus(ConsentStatus.Limited)}
        >
          <MemoizedFormattedMessage id={'consentAcceptNecessary'} />
        </button>

        <a className={'impressum'} href={process.env.REACT_APP_IMPRESSUMLINK}>
          <MemoizedFormattedMessage id={'imprint'} />
        </a>
      </Modal>
      {children}
    </ConsentContext.Provider>
  );
};

export {ConsentStatus, ConsentContext};
export default Consent;
