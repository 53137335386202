import Card, {CardStatus} from './Card';
import PlayedCard from './PlayedCard';
import Menu from './Menu';
import MenuItem from './MenuItem';
import Modal from 'react-modal';
import {useState} from 'react';

Modal.setAppElement('#root');

function Test() {
  const cards = [1, 2, 3, 5, 8, 13, 20];
  const [modalOpen, setModalOpen] = useState(true);

  function modalDialog() {
    return (
      <Modal isOpen={modalOpen} className="Modal">
        <h2>PlanningPoker</h2>
        <p>
          PlanningPoker ist eine beliebte Schätzmethode, die in verschiedenen
          agilen Vorgehensweisen verwendet wird. Jede Person gibt verdeckt eine
          Schätzung ab, hinterher wird verglichen und diskutiert. Durch die
          verdeckte Schätzung wird eine Beeinflussung vermieden.
        </p>
        <input className={'input-name'} placeholder={'Name'} type={'text'} />
        <br />
        <button className={'start-button'} onClick={() => setModalOpen(false)}>
          Neue Runde starten
        </button>
      </Modal>
    );
  }

  return (
    <div className="App">
      {modalDialog()}
      <header className="app-header">
        <Menu>
          <MenuItem text={'i fly out'} onClick={() => {}} />
          <MenuItem text={'i fly out'} onClick={() => {}} />
          <MenuItem text={'i fly out'} onClick={() => {}} />
          <MenuItem text={'i fly out'} onClick={() => {}} />
          <MenuItem
            text={'Impressum'}
            onClick={() =>
              window.open('https://www.koljatm.de/impressum/', '_blank')
            }
          />
        </Menu>
        <h1>Planning Poker</h1>
      </header>
      <div className={'spacer1'}></div>
      <div className={'play-area'}>
        <PlayedCard value={3} status={CardStatus.Hidden} playerName={'Alice'} />
        <PlayedCard value={5} status={CardStatus.Hidden} playerName={'Bob'} />
        <PlayedCard
          value={13}
          status={CardStatus.Hidden}
          playerName={'Claradsfgsdgdfloremp ipsum'}
        />
        <PlayedCard value={3} status={CardStatus.Hidden} playerName={'Alice'} />
        <PlayedCard value={5} status={CardStatus.Hidden} playerName={'Bob'} />
        <PlayedCard
          value={13}
          status={CardStatus.Hidden}
          playerName={'Clara lorem'}
        />
        <PlayedCard value={3} status={CardStatus.Hidden} playerName={'Alice'} />
        <PlayedCard value={5} status={CardStatus.Hidden} playerName={'Bob'} />
        <PlayedCard
          value={13}
          status={CardStatus.Hidden}
          playerName={'Clara loremipsum'}
        />
        <PlayedCard value={3} status={CardStatus.Hidden} playerName={'Alice'} />
        <PlayedCard value={5} status={CardStatus.Hidden} playerName={'Bob'} />
        <PlayedCard
          value={13}
          status={CardStatus.Hidden}
          playerName={'Clara loremipsum'}
        />
        <PlayedCard value={3} status={CardStatus.Hidden} playerName={'Alice'} />
        <PlayedCard value={5} status={CardStatus.Hidden} playerName={'Bob'} />
        <PlayedCard
          value={13}
          status={CardStatus.Hidden}
          playerName={'Clara loremipsum'}
        />
      </div>
      <div className={'spacer2'}></div>
      <div className={'controls'}>
        <button className={'round-control'}>▶️ Start</button>
        <div className={'info-text'}>Schätzung auswählen</div>
      </div>
      <div className={'cards'}>
        {cards.map((cardValue, index) => (
          <Card
            key={'card-' + index}
            value={cardValue}
            status={CardStatus.Hidden}
          />
        ))}
      </div>
    </div>
  );
}

export default Test;
