import {useState} from 'react';

function Menu({children}) {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={'menu ' + (open ? 'open' : 'closed')}
      onClick={() => setOpen(!open)}
    >
      <svg
        stroke="currentColor"
        fill="currentColor"
        viewBox="0 0 512 512"
        height="4vh"
        width="4vh"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M32 96v64h448V96H32zm0 128v64h448v-64H32zm0 128v64h448v-64H32z" />
      </svg>
      <div className={'flyout'}>{children}</div>
    </div>
  );
}

export default Menu;
