import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Test from './Test';
import Modal from 'react-modal';
import Consent, {ConsentStatus} from './Consent';
import {ConsentContext} from './Consent';
import Datenschutz from './Datenschutz';
import English from './lang/en.json';
import German from './lang/de.json';
import {IntlProvider} from 'react-intl';
import Privacy from './Privacy';

Modal.setAppElement('#root');

const locale = navigator.language;
let lang;
if (locale?.startsWith('de')) {
  lang = German;
} else {
  lang = English;
}

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale={locale} messages={lang}>
      <ConsentContext.Provider value={ConsentStatus.Unknown}>
        <Router>
          <Switch>
            <Route path="/datenschutz">
              <Datenschutz />
            </Route>
            <Route path="/privacy">
              <Privacy />
            </Route>
            <Route path="/test">
              <Test />
            </Route>
            <Route path="/">
              <Consent>
                <App />
              </Consent>
            </Route>
          </Switch>
        </Router>
      </ConsentContext.Provider>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
