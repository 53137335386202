import useFirebase from './hooks/useFirebase';
import {
  addDoc,
  collection,
  deleteField,
  doc,
  DocumentSnapshot,
  getFirestore,
  onSnapshot,
  setDoc,
  Unsubscribe,
} from 'firebase/firestore';
import {getAuth, signInAnonymously} from 'firebase/auth';

import {useHistory, useLocation} from 'react-router-dom';
import * as qs from 'qs';
import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import Menu from './Menu';
import MenuItem from './MenuItem';
import PlayedCard from './PlayedCard';
import Card, {CardStatus} from './Card';
import {GameData, GameDataUpdate, GameStatus} from './GameTypes';
import Modal from 'react-modal';
import useForceUpdate from './hooks/useForceUpdate';
import {ConsentContext, ConsentStatus} from './Consent';
import QRCode from 'react-qr-code';
import {useIntl} from 'react-intl';
import MemoizedFormattedMessage from 'react-intl/lib/src/components/message';

const decks = {
  simple: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  hours: [1, 2, 4, 6, 8, 12, 16, 20, 24, 36, 48],
  fibo: [1, 2, 3, 5, 8, 13, 21],
  fibo20: [1, 2, 3, 5, 8, 13, 20],
};

function App() {
  const [firebase] = useFirebase();
  const [session, setSession] = useState('');
  const [userId, setUserId] = useState('');
  const [data, setData] = useState<GameData>({
    status: GameStatus.Active,
    owner: '',
    cards: decks['fibo20'],
    participants: {},
  });
  const [showSessionLink, setShowSessionLink] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const nameRef = useRef<HTMLInputElement>(null);
  const updater = useForceUpdate();
  const consent = useContext(ConsentContext);

  let unsub: Unsubscribe | undefined;

  const intl = useIntl();
  useEffect(() => {
    const {sessionId} = qs.parse(location.search, {ignoreQueryPrefix: true});
    if (typeof sessionId === 'string') setSession(sessionId);

    if (!userId && consent !== ConsentStatus.Unknown) {
      initUser();
    }
  }, [consent]);

  useEffect(() => {
    if (firebase && session) {
      const firestore = getFirestore(firebase);
      const docRef = doc(firestore, 'planningPoker', session);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      unsub = onSnapshot(docRef, onDocumentUpdate);
      return function cleanup() {
        unsub && unsub();
      };
    }
    return;
  }, [firebase, session, userId]);

  const onDocumentUpdate = (doc: DocumentSnapshot<GameData>) => {
    const gameData = doc.data();
    if (!gameData) {
      unsub && unsub();
      setSession('');
      return;
    }
    updateData(gameData);
  };

  function updateData(data: GameData) {
    setData(data);
  }

  const startNewSession = async (name: string) => {
    if (!firebase) {
      return;
    }
    const db = getFirestore(firebase);
    const coll = collection(db, '/planningPoker/');
    const newGame: GameData = {
      status: GameStatus.Active,
      owner: userId,
      cards: decks['fibo20'],
      participants: {[userId]: {name: name}},
    };
    const docRef = await addDoc(coll, newGame);
    setSession(docRef.id);
    setShowSessionLink(true);
    history.push('/?sessionId=' + docRef.id);
  };

  function addData(newData: GameDataUpdate) {
    if (firebase) {
      const db = getFirestore(firebase);
      setDoc(doc(db, '/planningPoker/', session), newData, {merge: true});
    }
  }

  async function initUser() {
    if (firebase) {
      const auth = getAuth(firebase);
      const credentials = await signInAnonymously(auth);
      setUserId(credentials.user.uid);
      return credentials.user.uid;
    }
    return '';
  }

  async function onNameChange(name: string) {
    let key = userId;
    if (!userId) {
      key = await initUser();
    }
    addData({
      participants: {[key]: {name: name}},
    });
  }

  function getName(): string {
    if (data?.participants[userId]) {
      return data.participants[userId]?.name;
    } else {
      return '';
    }
  }

  function setCards(deck: keyof typeof decks) {
    addData({cards: decks[deck]});
  }

  function getMenu() {
    return (
      <Menu>
        <MenuItem
          text={<MemoizedFormattedMessage id={'showLink'} />}
          onClick={() => {
            setShowSessionLink(true);
          }}
        />
        <MenuItem
          text={<MemoizedFormattedMessage id={'cardsSimple'} />}
          onClick={() => setCards('simple')}
        />
        <MenuItem
          text={<MemoizedFormattedMessage id={'cardsHours'} />}
          onClick={() => setCards('hours')}
        />
        <MenuItem
          text={<MemoizedFormattedMessage id={'cardsFibo20'} />}
          onClick={() => setCards('fibo20')}
        />
        <MenuItem
          text={<MemoizedFormattedMessage id={'cardsFibo'} />}
          onClick={() => setCards('fibo')}
        />
        <MenuItem
          text={<MemoizedFormattedMessage id={'menuItemPrivacy'} />}
          onClick={() =>
            window.open(intl.formatMessage({id: 'privacyLink'}), '_blank')
          }
        />
        <MenuItem
          text={<MemoizedFormattedMessage id={'imprint'} />}
          onClick={() =>
            window.open(process.env.REACT_APP_IMPRESSUMLINK, '_blank')
          }
        />
      </Menu>
    );
  }

  function stopRound() {
    addData({status: GameStatus.Idle});
  }
  function startRound() {
    const newData: GameDataUpdate = {...data, status: GameStatus.Active};
    for (const key in newData.participants) {
      const participant = newData.participants[key];
      if (participant && 'cardValue' in participant)
        participant.cardValue = deleteField();
    }
    addData(newData);
  }

  function getControls() {
    function getStats() {
      let sum = 0;
      let count = 0;
      let max = 0;
      let min = Infinity;
      for (const key in data.participants) {
        const cardValue = data.participants[key].cardValue;
        if (cardValue) {
          sum += cardValue;
          count++;
          max = Math.max(max, cardValue);
          min = Math.min(min, cardValue);
        }
      }
      return count ? `min:${min} max:${max} ⌀:${(sum / count).toFixed(1)}` : '';
    }

    switch (data.status) {
      case GameStatus.Idle:
        return (
          <>
            <button className={'round-control'} onClick={startRound}>
              ▶️ <MemoizedFormattedMessage id={'buttonStart'} />
            </button>
            <div className={'info-text'}>{getStats()}</div>
          </>
        );
      case GameStatus.Active:
        return (
          <>
            <button className={'round-control'} onClick={stopRound}>
              ⏹️ <MemoizedFormattedMessage id={'buttonDone'} />
            </button>
            <div className={'info-text'}>
              <MemoizedFormattedMessage id={'chooseEstimate'} />
            </div>
          </>
        );
      default:
        return null;
    }
  }
  function getPlayArea() {
    const playedCards = [];
    for (const key in data.participants) {
      playedCards.push(
        <PlayedCard
          key={key}
          playerName={data.participants[key].name}
          value={data.participants[key]?.cardValue}
          status={
            data.participants[key]?.cardValue
              ? data.status === GameStatus.Idle
                ? CardStatus.Revealed
                : CardStatus.Hidden
              : CardStatus.Unknown
          }
          highlighted={key === userId}
          onDelete={() => deletePlayer(key)}
          allowDelete={data.owner === userId}
        />
      );
    }
    return playedCards.sort(
      (a, b) => a.key?.toString()?.localeCompare(b.key?.toString() || '') || 0
    );
  }

  function deletePlayer(playerId: string) {
    addData({
      participants: {
        [playerId]: deleteField(),
      },
    });
  }

  function onCardClick(cardValue: number) {
    if (data.status !== GameStatus.Active) {
      return;
    }
    const newData: GameDataUpdate = {
      participants: {[userId]: {cardValue: cardValue}},
    };

    let completed = true;
    const participantData = {...data.participants, ...newData.participants};
    for (const key in participantData) {
      if (!participantData[key]['cardValue']) {
        completed = false;
      }
    }

    if (completed) {
      newData.status = GameStatus.Idle;
      console.log('\x1b[35m%s\x1b[0m', '>> completed', newData);
    }
    addData(newData);
  }

  function getCards() {
    return (
      <>
        {data?.cards?.map((cardValue, index) => (
          <Card
            key={'card-' + index}
            value={cardValue}
            status={CardStatus.Revealed}
            onClick={() => onCardClick(cardValue)}
            highlighted={cardValue === data.participants[userId]?.cardValue}
          />
        ))}
      </>
    );
  }

  function modalDialogStart() {
    return (
      <Modal
        isOpen={consent !== ConsentStatus.Unknown && (!session || !getName())}
        className="Modal"
      >
        <h2>
          <MemoizedFormattedMessage id={'title'} />
        </h2>
        <p>
          <MemoizedFormattedMessage id={'explanationBlurb'} />
        </p>
        <form onSubmit={e => e.preventDefault()}>
          <input
            ref={nameRef}
            className={'input-name'}
            placeholder={'Name'}
            type={'text'}
            onChange={updater}
          />
          <br />
          {(session && (
            <button
              className={'start-button'}
              disabled={!nameRef?.current?.value}
              onClick={() => onNameChange(nameRef.current?.value || '')}
            >
              <MemoizedFormattedMessage id={'buttonJoin'} />
            </button>
          )) || (
            <button
              className={'start-button'}
              disabled={!nameRef?.current?.value}
              onClick={() => startNewSession(nameRef.current?.value || '')}
            >
              <MemoizedFormattedMessage id={'buttonStartNew'} />
            </button>
          )}
        </form>
      </Modal>
    );
  }

  function displaySessionLink() {
    const sessionLink = `${process.env.REACT_APP_BASE_URL}?sessionId=${session}`;
    const size = Math.min(window.innerWidth, window.innerHeight, 400) * 0.6;
    return (
      showSessionLink &&
      session && (
        <div className={'show-session-link'}>
          <h3>
            <MemoizedFormattedMessage id={'inviteLink'} />
          </h3>
          <p>
            <MemoizedFormattedMessage id={'inviteExplanation'} />
            <br />
            <a href={sessionLink}>{sessionLink}</a>
            <div className={'qr-code'}>
              <QRCode size={size} value={sessionLink} />
            </div>
          </p>
          <button
            className={'close-dialog'}
            onClick={() => setShowSessionLink(false)}
          >
            <MemoizedFormattedMessage id={'buttonClose'} />
          </button>
        </div>
      )
    );
  }

  return (
    <div className="App">
      {modalDialogStart()}
      {displaySessionLink()}
      <header className="app-header">
        {getMenu()}
        <h1>
          <MemoizedFormattedMessage id={'title'} />
        </h1>
      </header>
      <div className={'play-area'}>{getPlayArea()}</div>
      <div className={'controls'}>{getControls()}</div>
      <div className={'cards'}>{getCards()}</div>
    </div>
  );
}

export default App;
