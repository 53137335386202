import Card from './Card';

function PlayedCard({
  value,
  status,
  playerName,
  highlighted = false,
  onDelete = () => {},
  allowDelete = false,
}) {
  return (
    <div className={'played-card'}>
      <Card value={value} status={status} highlighted={highlighted} />
      <label className={'player'}>{playerName}</label>
      {allowDelete && (
        <div className={'delete'} onClick={onDelete}>
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
            <g id="Layer_1">
              <ellipse
                stroke="currentColor"
                ry="7"
                rx="7"
                id="svg_1"
                cy="12"
                cx="12"
                fill="#fff"
                strokeWidth="2"
              />
              <line
                id="svg_3"
                y2="17"
                x2="17"
                y1="7"
                x1="7"
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
              />
              <line
                id="svg_4"
                x1="17"
                y1="7"
                x2="7"
                y2="17"
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
              />
            </g>
          </svg>
        </div>
      )}
    </div>
  );
}

export default PlayedCard;
